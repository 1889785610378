<template>
  <Box>
    
    <ErrorRow :error="error" /> 
    <SuccessRow :message="message" /> 
    
    <Row>
      <Column :width="10">
        <Box :showBorder="true">
          <ValueRow align="left"> {{ MC.Filters.Title.value() }} </ValueRow>
          <Row>
            <Column :width="15">
              <br>
            </Column>
          </Row>
        </Box>
      </Column>
      
      <Column :width="5">
        <Box :showBorder="true">
          <ValueRow align="left"> {{ MC.Actions.Title.value() }} </ValueRow>
          <Row>
            <Column :width="5" />
            <Column :width="5" />
            <Column :width="5">
              <Button v-on:click="refresh"> {{ MC.Actions.Refresh.value() }} </Button>
            </Column>
          </Row>
        </Box>
      </Column>
    </Row>
        
    <TitleRow>{{ schedule.name() }}</TitleRow>

    <LabelValueRow :labelWidth="2">
      <template #label> {{ MC.Schedule.Description.value() }} </template>
      <template #value> {{ schedule.description() }} </template>
    </LabelValueRow>
    
    <LabelValueRow :labelWidth="2">
      <template #label> {{ MC.Schedule.Declaration.value() }} </template>
      <template #value> {{ schedule.declaration() }} </template>
    </LabelValueRow>
    
    <Row align="left" v-for="(question, index) in questions" :key="question.id()" :class="Strings.isEven(index) ? 'rowEven row' : 'rowOdd row'" >
      <Column :width="15">
        <Box>
          <ValueRow align="left">
            ({{ question.part() }})
            {{ question.name() }}
          </ValueRow>
          <Row>
            <Column :width="1" />
            <Column :width="14">
              <Box>
                <TitleRow v-if="options(question, false).length > 0" align="left" :subtitle="true">{{ MC.Schedule.Options.Minors.value() }} </TitleRow>
                <Row align="left" v-for="(option) in options(question, false)" :key="option.id()">
                  <Column :width="15">
                    <Box>
                      <ValueRow align="left" >
                        ({{ option.position() }})
                        {{ option.name() }}
                        {{ option.value() }}
                      </ValueRow>
                      <Row v-if="option.hasNotes()">
                        <Column :width="1" />
                        <Column :width="14">
                          <Box>
                            <TitleRow align="left" :subtitle="true">{{ MC.Schedule.Notes.value() }} </TitleRow>
                            <ValueRow align="left" v-for="(note) in notes(option)" :key="note.id()">
                              {{ note.position() }}
                              {{ note.value() }}
                            </ValueRow>
                          </Box>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                </Row>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column :width="1" />
            <Column :width="14">
              <Box>
                <TitleRow v-if="options(question, true).length > 0" align="left" :subtitle="true">{{ MC.Schedule.Options.Majors.value() }} </TitleRow>
                <Row align="left" v-for="(option) in options(question, true)" :key="option.id()">
                  <Column :width="15">
                    <Box>
                      <ValueRow align="left" >
                        ({{ option.position() }})
                        {{ option.name() }}
                        {{ option.value() }}
                      </ValueRow>
                      <Row v-if="option.hasNotes()">
                        <Column :width="1" />
                        <Column :width="14">
                          <Box>
                            <TitleRow align="left" :subtitle="true">{{ MC.Schedule.Notes.value() }} </TitleRow>
                            <ValueRow align="left" v-for="(note) in notes(option)" :key="note.id()">
                              {{ note.value() }}
                            </ValueRow>
                          </Box>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                </Row>
              </Box>
            </Column>
          </Row>
        </Box>
      </Column>
    </Row>
    
    <Row v-if="isAdmin">
      <Column>
        <Button>Button </Button>
        <Value> Value </Value>
      </Column>
    </Row>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <SpacerRow v-else/>
    
  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

//import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import Schedule from "@/domain/model/schedule/Schedule.js";

export default {
  name: 'portal-customer-operator-schedule-details',
  components: {
    Button,
    ErrorRow,
    SuccessRow,
    SpacerRow,
    Value, ValueRow,
    Box, Row, Column,
    TitleRow, LoadingRow, LabelValueRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: false,
      loadingMessage: "",
      MC: new MC(),
      
      Strings: StringUtils,
      Contents: ContentUtils,
      total: 0,
      page: 0,
      pageSize: 10,
      pageNumber: 0,
      showSessionTimeoutDialog: false,
      
      schedule: new Schedule(this.domain),
      questions: [],
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    scheduleId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.message = null;
      this.loadingMessage = "";
      this.loadData();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    loadData: function() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.error = null;
      this.loadingMessage = this.MC.Status.Loading.value();
      this.schedule = new Schedule(this.domain);
      this.questions = [];
      
      var event = this.domain.events().schedules().list(this.scheduleId);
      event.send(this.loadScheduleListListener);
    },
    
    loadScheduleListListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        this.schedule = this.domain.schedules().findById(this.scheduleId);
        this.questions = this.schedule.questions().find().sort();
      }
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
    },
    
    refresh: function () {
      this.loadData();
    },
    
    options: function(question, majors) {
      if (!this.domain) {
        return [];
      }
      var options = question.options().find();
      options = options.removeRemarks();
      var sorted = [];
      if (majors) {
        sorted = options.majors().sort();
      } else {
        sorted = options.minors().sort();
      }
      return sorted;
    },
    notes: function(option) {
      if (!this.domain) {
        return [];
      }
      var notes = option.noteMap().find().sort();
      return notes
    }
  },
  
};
</script>
